import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"

import Seo from "../components/seo"

import styled from 'styled-components'

import { Suspense , useRef, useState, useMemo, useEffect } from 'react';

import * as d3 from 'd3';
// import tree from 'd3-tree';

import { textwrap } from 'd3-textwrap';

// import { Modal } from 'bootstrap';

const RepositorioPage = () => {

  const isBrowser = typeof window !== 'undefined'
  const [svgWidth, setSvgWidth] = useState(isBrowser ? window.innerWidth : 0)  
  const [svgHeight, setSvgHeight] = useState(isBrowser ? (window.innerHeight*1.5) : 2000)  

  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "bg-repo-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "obj.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

      }
    `
  )

  // const cdl_muros = React.lazy(() => import('../images/cdl_muros.obj'))
  

  // const obj = useLoader(OBJLoader, '../images/cdl_muros.obj')

  // const data2 = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "obj.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid

  const modalRef = useRef(null);

  const iframeRef = useRef(null);
  const iframeDivRef = useRef(null);
  const imageRef = useRef(null);
  
  const itemTitulo = useRef(null);
  const itemFecha = useRef(null);
  const itemMedio = useRef(null);
  const itemFuente = useRef(null);
  const itemLink = useRef(null);

  // const showModal = (link) => {
  //   console.log(link);
  //   // let modal = new Modal(document.getElementById('myModal'), {});
  //   // document.getElementById('myModal').show();
  //   // jQuery('#myModal').modal()
  // }

  const DrawTable = () => {

    const [data,setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    let firstRun = false;

    const handleChange = event => {
        if (event.target.value.length > 0) {
          setSearchTerm(event.target.value);
        } else {
          getData();
        }
        
      };

    useEffect(() =>{
      getData();
    }, [])

    useEffect(() => {
      let dataFiltered = data.filter(obj => Object.values(obj).some(val => val?val.toString().toLowerCase().includes(searchTerm):false));
      setData(dataFiltered);
      
    }, [searchTerm])

    const getData = () => {
      let prensa = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Prensa"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

      let fotografias = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Fotografias"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

      let mAudiovisual = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Material audiovisual"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

      let pActual = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Prensa actual"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

      let mDescargables = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Materiales descargables Proyecto Curva de la Luz"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });                  

      Promise.all([prensa, fotografias, mAudiovisual, pActual, mDescargables]).then(values => {

        setData([{ 'Break' : true, 'Title' : 'Prensa'}, ...values[0], { 'Break' : true, 'Title' : 'Fotografias'}, ...values[1], { 'Break' : true, 'Title' : 'Medios audiovisuales'}, ...values[2], { 'Break' : true, 'Title' : 'Prensa actual'}, ...values[3], { 'Break' : true, 'Title' : 'Descargas'}, ...values[4]])    
          
        // console.dir(values);
      
      }); 

    }

    const imgOrIframe = (val) => {
      if (val['URL'] && val['URL'].slice(val['URL'].length-4, val['URL'].length) === '.pdf') {
        return (<a role='button' href={'#'} onClick={ () => {
          imageRef.current.style.display = 'none';
          itemLink.current.style.display = 'none';
          iframeDivRef.current.style.display = 'none';
          iframeRef.current.style.display = 'block';
          iframeRef.current.src=val['URL'];
          itemTitulo.current.innerHTML=val['Título'] ? val['Título'] : '';
          itemMedio.current.innerHTML=val['Medio'] ? val['Medio']+', ' : '';
          itemFecha.current.innerHTML=val['Fecha (Día, mes año)'] ? val['Fecha (Día, mes año)'] : '';
          itemFuente.current.innerHTML=val['Fuente'] ? val['Fuente'] : '';
          }
        } data-bs-toggle="modal" data-url={val['URL']} data-bs-target="#repo-modal">Ver PDF</a>)
      } else if (val['URL'] && val['URL'].slice(0, 4) === 'http') {
        return (<a role='button' href={'#'} onClick={ () => {
          imageRef.current.style.display = 'none';
          itemLink.current.style.display = 'block';
          itemLink.current.style.display = 'block';
          iframeRef.current.style.display = 'none'
          iframeDivRef.current.style.display = 'block';
          
          iframeDivRef.current.innerHTML = val['Iframe'];

          
          iframeRef.current.src=val['Embed'];
          itemTitulo.current.innerHTML=val['Título'] ? val['Título'] : '';
          itemMedio.current.innerHTML=val['Medio'] ? val['Medio']+', ' : '';
          itemFecha.current.innerHTML=val['Fecha (Día, mes año)'] ? val['Fecha (Día, mes año)'] : '';
          itemFuente.current.innerHTML=val['Fuente'] ? val['Fuente'] : '';
          itemLink.current.href=val['URL'];
          }
        } data-bs-toggle="modal" data-url={val['URL']} data-bs-target="#repo-modal">Ver contenido</a>)
      } else if (val['URL']) {
        return (<a role='button' href={'#'} onClick={ () => {
          iframeRef.current.style.display = 'none';
          itemLink.current.style.display = 'none';
          iframeDivRef.current.style.display = 'none';
          imageRef.current.style.display = 'block';
          imageRef.current.src=val['URL'];
          itemTitulo.current.innerHTML=val['Título'] ? val['Título'] : '';
          itemMedio.current.innerHTML=val['Medio'] ? val['Medio']+', ' : '';
          itemFecha.current.innerHTML=val['Fecha (Día, mes año)'] ? val['Fecha (Día, mes año)'] : '';
          itemFuente.current.innerHTML=val['Fuente'] ? val['Fuente'] : '';
          itemFuente.current.innerHTML=val['Fuente'];
          
          }
        } data-bs-toggle="modal" data-url={val['URL']} data-bs-target="#repo-modal">Ver imagen</a>)
      }
    } 

      return (<div className={'table-responsive mt-5'}>

        <div className={"input-group input-group-sm mb-3"}>
          <span className={"input-group-text"} id="basic-addon1">Búsqueda por texto</span>
          <input type="text" className={"form-control"} placeholder="Nombre, título, autor, fuente, año, etc." onKeyDown={handleChange} aria-label="Búsqueda" aria-describedby="basic-addon1"/>
        </div>


        <table className={'table table-striped table-sm'}><tbody>
            <tr className={''}>
              <td className="text-black ribbon-gray text-white fw-bold" style={{backgroundolor: '#1978ae !important'}}>Autor</td>
              <td className="text-black ribbon-navy text-white fw-bold">Titulo</td>
              <td className="text-black ribbon-red text-white fw-bold">Medio</td>
              <td className="text-black ribbon-blue text-white fw-bold">Fuente</td>
              <td className="text-black ribbon-blue text-white fw-bold">Fecha</td>
              <td className="text-black ribbon-blue text-white fw-bold">URL</td>
            </tr>

        {data.map((val, key) => {

            if (val['Título']) { return (
              <tr key={key}>
                <td>{val['Autor']}</td>
                <td>{val['Título']}</td>
                <td>{val['Medio']}</td>
                <td>{val['Fuente']}</td>
                <td>{val['Fecha (Día, mes año)']}</td>
                <td>
                  {imgOrIframe(val)}
                </td>
              </tr>
            )} else if (val['Break']) {
              return (
            <tr className={''}>
              <td className="text-black ribbon-gray text-white fw-bold" style={{backgroundolor: '#1978ae !important'}}>{val['Title']}</td>
              <td className="text-black ribbon-gray text-white fw-bold"></td>
              <td className="text-black ribbon-gray text-white fw-bold"></td>
              <td className="text-black ribbon-gray text-white fw-bold"></td>
              <td className="text-black ribbon-gray text-white fw-bold"></td>
              <td className="text-black ribbon-gray text-white fw-bold"></td>
            </tr>     )         

            }

        })}     
      </tbody></table></div>);
  }

  const DrawChart = () => {

    d3.textwrap = textwrap;

    const [treeDataSource,setTreeDataSource] = useState([]);
    const svgRef = useRef(null);

    // let svgWidth;
    // let svgHeight;

    // if (typeof window !== `undefined`) {
    //   svgWidth = window.innerWidth-200;
    //   svgHeight = window.innerHeight*1.5;
    // } else {
    //   svgWidth = '100%';
    //   svgHeight = 2000;
    // }

    var wrap = d3.textwrap().bounds({height: 50, width: 200, x: 15, y: -15});

    const getTreeData = () => {

        let prensa = new Promise((resolve, reject) => {
          fetch(
            "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Prensa"
          )
            .then((res) => res.json())
            .then((data) => {
              resolve(data);
            });      
        });

        let fotografias = new Promise((resolve, reject) => {
          fetch(
            "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Fotografias"
          )
            .then((res) => res.json())
            .then((data) => {
              resolve(data);
            });      
        });

      let mAudiovisual = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Material audiovisual"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

      let pActual = new Promise((resolve, reject) => {
        fetch(
          "https://opensheet.elk.sh/1HnOngNCBpJHBp2_GQbo7X_83dDXW-QMdMw-ybb7oaVA/Prensa actual"
        )
          .then((res) => res.json())
          .then((data) => {
            resolve(data);
          });      
      });

        Promise.all([prensa, fotografias, mAudiovisual, pActual]).then(values => {
          // setData([...values[0], ...values[1]])
          let parsedPrensa = values[0].map( item => {
            return {'name': item['Título']}
          });

          let parsedFotografias = values[1].map( item => {
            return {'name': item['Título']}
          });

          let parsedmAudiovisual = values[2].map( item => {
            return {'name': item['Título']}
          });

          let parsedpActual = values[3].map( item => {
            return {'name': item['Título']}
          });          

          return ({ 
            'name': 'Curva de la luz', 
            'parent': 'null', 
            children: [
              {'name': 'Prensa', 'children': parsedPrensa},
              {'name': 'Fotografias', 'children': parsedFotografias},
              {'name': 'Material audiovisual', 'children': parsedmAudiovisual},
              {'name': 'Prensa actual', 'children': parsedpActual}
              ]
            })
        }).then( (treeData) => {
          setTreeDataSource(treeData);
        })
              
    }

    useEffect( () => {
      // drawTree();

      if (typeof window !== `undefined`) {
        setSvgWidth(window.innerWidth-(window.innerWidth/12*2.5));
        setSvgHeight(window.innerHeight*1);
      }

      getTreeData();

    }, [])

    useEffect(() => {
      drawTree(treeDataSource);
    }, [treeDataSource])  

    const drawTree = (resData) => {

      let height = svgHeight;
      let width = svgWidth;
      
      let data = d3.hierarchy(resData)
      // const svg = d3.create("svg");
      const svg = d3.select(svgRef.current);

      let diameter = height * 0.75;
      let radius = diameter / 3.25;
      let tree = d3.tree()
          .size([2 * Math.PI, radius])
          .separation(function(a, b) { 
              return (a.parent == b.parent ? 1 : 2) / a.depth; 
          });

      let treeData = tree(data);

      let nodes = treeData.descendants();
      let links = treeData.links();

      let graphGroup = svg.append('g').attr('transform', "translate("+(width/2)+","+(height/2)+")");

      graphGroup.selectAll(".link")
        .data(links)
        .join("path")
        .attr("class", "link")
        .attr("d", d3.linkRadial()
          .angle(d => d.x)
          .radius(d => d.y));


      let node = graphGroup
              .selectAll(".node")
              .data(nodes)
              .join("g")
              .attr("class", "node")
              .attr("transform", function(d){
                  console.dir(d);
                  if (d.data.name == 'Curva de la luz') {
                    return `translate(${d.y}, 0)`;  
                  } else if (d.data.name == 'Prensa') {
                    return `rotate(${d.x * 180 / Math.PI - 90})` + `translate(${d.y}, 0)`;  
                  } else {
                    return `rotate(${d.x * 180 / Math.PI - 90})` + `translate(${d.y}, 0)`;  
                  }
                  // return `rotate(${d.x * 180 / Math.PI - 90})` + `translate(${d.y}, 0)`;
                  
              });        

      node.append("circle").attr("r", 3);

      node.append("text")
        .attr("font-family", "sans-serif")
        .attr("font-size", 6)
        .attr("width", 100)
        .attr("dx", function(d) { return d.x < Math.PI ? 8 : -8; })
        .attr("dy", ".25em")
        .attr("text-anchor", function(d) { 
            return d.x < Math.PI ? "start" : "end"; 
        })
        .attr("transform", function(d) { 
            return d.x < Math.PI ? null : "rotate(180)"; 
        })
        .text( function (d) {
          return d.data.name;
          // let dArray = d.data.name.split(" ");
          // console.log(dArray);
          // if (!(dArray.length > 1)) {
            // return d.data.name;
          // } else {
            // for (let i = 0; i < dArray.length ; i++) {

              // this.append("tspan").text(dArray[i]+" ");
              // return <tspan>dArray[i]</tspan>
            // }            
          // }
        })
        // .text(function(d) { 
          // let dArray = d.data.name.split(" ");
          // return d.data.name;
        // });


        // node.select("text")
        //   .append(function (d) {
        //     let dArray = d.data.name.split(" ");
        //     console.dir(dArray);
        //     // dArray.forEach((item) => {
        //       // return <tspan></tspan>
        //     // });
        //   });

      d3.selectAll('text').call(wrap);


    }


    // useEffect( () => {
    //   // console.dir(data);
    // }, [data]);

    

    


    return (<svg className={`d-none d-lg-block d-xl-block`} ref={svgRef} width={svgWidth} height={svgHeight} />);

  }

  return (

      <Layout>

        <Seo title="Repositorio" />


          <BackgroundImage
            Tag="section"
            className={'container-fluid  g-0 d-flex flex-column'}
            fluid={bg1}
            // backgroundColor={`#040e18`}
          >

          	<div className={`row flex-1 pt-3`}>
        		        		
              <div className={`col h-100`}>

              

                <p className={`col-md-5 col-lg-5 col-xl-5 col-xs-10 col-sm-10 text-white text-end bg-black mt-5 pb-0 mb-0 headline`}>Repositorio</p>
                <p className={`col-xs-10 col-sm-10 col-md-8 col-lg-8 col-xl-8 ps-5 pe-5 text-black text-end pt-0 mt-1`}>Compendio de archivos de video, audio, fotografías y documentos que se relacionen con el mural del Paso Inferior Santa Lucía en un sólo lugar y que puedan servir para orientar a profesores e investigadores de artes visuales, arquitectura, urbanismo y público general con respecto al arte en espacio público.</p>
                

              

{/*                <div className={`text-center`}>
                  <StaticImage
                    alt={`Curva de la Luz`}
                    src="../images/repositorio.png"
                    width={300}
                    quality={95}
                    style={{ 
                    }}
                  />                  
                </div>*/}

                <div className={`col-10 offset-1 mt-3 rounded-3 p-3 pt-3 pb-0 mb-5 d-none d-lg-block d-xl-block`} style={{ backgroundColor: 'rgba(49, 107, 181, 0.91)' }}>
                  <div style={{position: 'relative', width: '100%'}}>
                    <DrawChart />

                    <p className={`text-white fs-6 text-center mt-5 pb-0`}>Diagrama radial construído con la información del mural contenida en este compendio</p>
                  </div>
                </div>

                <div className={`col-10 offset-1 mt-3 mt-3 rounded-3 p-3 mb-5`} style={{ backgroundColor: 'rgba(49, 107, 181, 0.91)' }}>
                  <DrawTable />
                </div>

                               
                

              </div>

          	</div>

            <div className={`row`} style={{zIndex: -1}}>
              <div className={`col g-0`}>
                <StaticImage
                  alt={`Curva de la Luz`}
                  src="../images/bg-repo-2.jpg"
                  width={1920}
                  quality={95}
                  style={{
                    marginTop: '-150px',
                  }}
                />                 
              </div>
            </div>

            <div className={`row ribbon no-gutters`}>

            	<div className={`col-3 ribbon-blue`}></div>
            	<div className={`col-2 ribbon-red`}></div>
            	<div className={`col-4 ribbon-navy`}></div>
            	<div className={`col-3 ribbon-gray`}></div>

            </div>

          </BackgroundImage>


        <div ref={modalRef} className={"modal fade"} id="repo-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className={"modal-dialog"}>
            <div className={"modal-content"}>
              <div className={"modal-header"}>
                <h5 className={"modal-title"} id="staticBackdropLabel"></h5>
                <button type="button" className={"btn-close"} data-bs-dismiss="modal" aria-label="Cerrar"></button>
              </div>
              <div className={"modal-body"}>
                  <img className={"modal-img"} ref={imageRef} />
                  <iframe className={"modal-iframe"} ref={iframeRef}></iframe>
                  <div className={"modal-iframe-div"} ref={iframeDivRef}></div>
                  <p className={"modal-body-item-fuente p-0 m-0 fs-4"}><a target="_blank" ref={itemLink}>Link</a></p>
                 <p ref={itemTitulo} className={"modal-body-item-titulo p-0 m-0 fs-4"}></p>
                 <p className={"modal-body-item-medio p-0 m-0 fs-5"}><span ref={itemMedio}></span><span ref={itemFecha}></span></p>
                 <p ref={itemFuente} className={"modal-body-item-fuente p-0 m-0 fs-6"}></p>
                 
              </div>
            </div>
          </div>
        </div>
      
      </Layout>
    
  )

}

export default RepositorioPage;
